<template>
  <div class="contentWrapper">
    <h1>404: Not Found</h1>
    <div class="content">
      <p>The content can not be found. Try starting from the <router-link to="/">home</router-link> page or view our <router-link to="/products/">products</router-link> page.</p>
    </div>
  </div>
  
</template>
<script>
export default {
  name: "NotFound"
};
</script>
<style lang="scss" scoped>
.contentWrapper {
  text-align: center;
  padding-top:40px;

  h3 {
    margin-bottom: 0;
    color: #ffffff;
    font-weight: 700;
  }

  h3 ~ p {
    margin-top: 0px;
  }

  .content {
    padding-bottom: 40px;
    margin-bottom: 80px;
    max-width:50%;
    margin:0 auto;
  }

  a {
    text-decoration: underline;
    color: #ffffff;
  }
}
</style>